@import './mixins';

.h-100 {
  height: 100% !important;
}
.h-100v {
  height: 100vh !important;
}
.flex-column {
  flex-direction: column;
}
.row-center {
  justify-content: center;
}
.align-items-stretch {
  align-items: stretch;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mb-36 {
  margin-bottom: 36px !important;
}
.mt-36 {
  margin-top: 36px !important;
}
.mt-48 {
  margin-top: 48px !important;
}
.pb-24 {
  padding-bottom: 24px !important;
}

.m-12 {
  margin: 12px !important;
}

.pt-24 {
  padding-top: 24px !important;
}
.pl-24 {
  padding-left: 24px !important;
}
.pr-24 {
  padding-right: 24px !important;
}
.p-24 {
  padding: 24px;
  @include breakpoint(max-sm-mui) {
    padding: 16px;
  }
}

.pb-64 {
  padding-bottom: 64px !important;
}
.pt-64 {
  padding-top: 64px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}
