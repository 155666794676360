$min-color: #295bb7;
$blue20: #d4def1;
$blue10: #eaeff8;
$yellow: #f8b944;
$yellow20: #fef1da;
$yellow10: #fef8ec;
$red: #ff3838;
$red100: #ff6b72;
$red10: #fff0f1;
$red20: #ffe1e3;
$black: #050b17;
$gray50: #82858b;
$gray30: #b4b5b8;
$gray10: #e6e7e8;
$gray5: #f6f8fd;
$gray0: #fafafa;
$gray20: #eff0f5;
$green10: #e6faf3;
$yellow10: #fff9ec;
$white: #ffffff;
$blueHover: #214992;
$textAlign: center;
$background: #f6f8fd;
$blue102: #ecf2fe;
$blue100: #3e79f7;
$green100: #04d182;
$yellow100: #ffc542;
$inputHeight: 56px;
$inputReduis: 16px;
$inputBorderWidth: 1px;
$inputLabelFontSize: 16px;
$inputPading: 16px;
$inputTextWeight: 400;

$fontSize48: 48px;
$fontSize32: 32px;
$fontSize20: 20px;
$fontSize24: 24px;
$fontSize16: 16px;
$fontSize14: 14px;
$weight700: 700;
$weight600: 600;
$weight500: 500;
$weight400: 400;

$f-Regular: 'BalooBhaijaan2-Regular';
$f-Medium: 'BalooBhaijaan2-Medium';
$f-Sami-Bold: 'BalooBhaijaan2-SamiBold';
$f-Bold: 'BalooBhaijaan2-Bold';
$h-100: 100% !important;
$h-100vh: 100vh !important;
