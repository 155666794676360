@import '../../../assets/styles/var';

.message-modal {
  .MuiPaper-root {
    padding: 32px;
    width: 424px;
    /* background 1 */
    background: #ffffff;
    border-radius: 24px;
    box-shadow: none;
    text-align: center;

    .MuiDialogContent-root {
      padding: 0;
      overflow-y: hidden;
      .icon {
        width: 72px;
        height: 72px;
        display: inline-flex;
        align-items: center;
        background: $green10;
        justify-content: center;
        border-radius: 24px;
        margin-bottom: 24px;
      }
      .app-title {
        margin-bottom: 4px;
      }
    }
    .MuiDialogActions-root {
      justify-content: center;
      padding: 0;
      margin-top: 32px;
    }
  }
}
