@import '../../../assets/styles/var';
@import '../../../assets/styles/mixins';

.app-title {
  font-size: $fontSize32;
  font-family: $f-Sami-Bold;
  color: $black;
  margin: 0;
  line-height: 40px;
  @include breakpoint(max-sm-mui) {
    font-size: $fontSize24;
  }
}

.card-title {
  font-size: $fontSize16;
  font-family: $f-Sami-Bold;
  margin: 0;
}
.app-sub-title {
  font-size: $fontSize16;
  color: $gray50;
  margin: 0;
  line-height: 24px;
  @include breakpoint(max-sm-mui) {
    font-size: $fontSize14;
  }
}
.text1 {
  font-size: $fontSize14;
  font-family: $f-Medium;
  color: $gray50;
  margin: 0;
}
.text2 {
  font-size: $fontSize16;
  font-family: $f-Medium;
  color: $white;
  line-height: 24px;
  @include breakpoint(max-sm-mui) {
    font-size: $fontSize14;
  }
}
.app-bigTitle {
  font-size: $fontSize48;
  font-family: $f-Bold;
  color: $black;
  margin: 0;
  line-height: 56px;
  @include breakpoint(max-sm-mui) {
    font-size: $fontSize24;
  }
}
.app-specialTitle {
  font-size: $fontSize24;
  font-family: $f-Sami-Bold;
  color: $black;
  margin: 0;
  line-height: 32px;
  @include breakpoint(max-sm-mui) {
    font-size: $fontSize16;
  }
}

.line-through {
  text-decoration-line: line-through;
}
