@import '../../../assets/styles/var';
@import '../../../assets/styles/mixins';

.app-button {
  height: $inputHeight;
  border-radius: $inputReduis !important;
  padding: 0 !important;
  text-transform: none !important;
  line-height: normal;
  transition: all 0.3s ease-in;
  min-width: auto !important;
  box-shadow: none !important;
  font-family: $f-Medium;
  @include breakpoint(max-xs) {
    width: 100% !important;
  }
  &:hover {
    box-shadow: none !important;
  }
  .MuiButton-startIcon {
    margin: 0;
    margin-right: 12px;
    [dir='rtl'] & {
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      transform: rotateY(180deg);
      margin-right: 0;
      margin-left: 12px;
    }
  }
  .button-loading {
    margin-right: 10px;
    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
.hover-contained {
  &.Mui-disabled {
    background-color: $gray10 !important;
    color: $gray30 !important;
    cursor: not-allowed;
    .text2 {
      color: $gray30;
    }
  }
  &:hover {
    background-color: $blueHover !important;
  }
}
.MuiButton-containedSecondary {
  &:hover {
    background-color: $blue20 !important;
  }
}
.MuiButton-containedSuccess {
  &:hover {
    background-color: $gray10 !important;
  }
}
.MuiButton-text {
  height: auto;
  &:hover {
    background-color: transparent;
    svg {
      color: $min-color;
      path {
        fill: $min-color;
      }
    }
    .text2 {
      color: $min-color;
    }
  }
}

.cancel-btn {
  background-color: $red10 !important;
  &:hover {
    background-color: $red20 !important;
  }
  &:disabled {
    background-color: #fafafa !important ;
    color: $gray30 !important;
    .text2 {
      color: $gray30 !important;
    }
  }
}
.btn-upload-todys {
  height: 40px;
  padding: 0 24px !important;
  margin-left: 24px !important;
  [dir='rtl'] & {
    margin-left: 0 !important;
    margin-right: 24px !important;
  }
}
