@import './var';
@import './display';
@import './globalClasses';
/*import font*/
@font-face {
  font-family: 'BalooBhaijaan2-Regular';
  src: url('../fonts/BalooBhaijaan2-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'BalooBhaijaan2-Medium';
  src: url('../fonts/BalooBhaijaan2-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'BalooBhaijaan2-SamiBold';
  src: url('../fonts/BalooBhaijaan2-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'BalooBhaijaan2-Bold';
  src: url('../fonts/BalooBhaijaan2-Bold.ttf') format('truetype');
}
body,
html {
  width: 100%;
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d4def1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #295bb7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #214992;
  // background: #ab268c83;
}
body {
  box-sizing: border-box;
  font-family: $f-Regular;
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
  width: 100%;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
.auth-logo-conatiner {
  width: 72px;
  height: 72px;
  background: $blue10;
  border-radius: 24px;
  float: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Toastify {
  .Toastify__toast-container {
    .Toastify__progress-bar {
      background: $red;
    }
  }
}
.timer {
  margin-left: 8px;
  color: $min-color;
  font-size: 14px;
  font-family: $f-Medium;
  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 8px;
  }
  span {
    color: $gray50;
  }
}
.fish-amount {
  color: $gray30;
  font-family: $f-Sami-Bold;
  font-size: 16px;
}
.active-fish-amount {
  color: $min-color;
}

.fish-amount-btn {
  border: 1px solid $min-color !important;
  border-radius: 8px !important;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  min-width: 32px !important;
}
.mins-btn {
  border-color: $gray30 !important;
  &:hover {
    border-color: $min-color !important;
  }
}
.active-plus-btn {
  border-color: $blue10 !important;
  background-color: $blue10 !important;
  &:hover {
    border-color: $blue20 !important;
    background-color: $blue20 !important;
  }
}
.product-card-skeleton {
  height: 322px;
  border: 1px solid #e6e7e8;
  padding: 16px;
  border-radius: 16px;
}
.MuiOutlinedInput-root {
  font-family: $f-Regular !important;
}
.MuiOutlinedInput-input .MuiPickersLayout-root {
  .MuiPickersCalendarHeader-root {
    .MuiPickersArrowSwitcher-root {
      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          [dir='rtl'] & {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}
.MuiRating-root {
  color: $yellow;
  .MuiRating-iconEmpty {
    svg {
      color: $yellow;
    }
  }
}
.MuiLinearProgress-root {
  background-color: $gray10 !important;
  border-radius: 4px;
}
.ltrDirection {
  direction: ltr;
}
.rtlDirection {
  direction: rtl;
}
.center-item-gap-3{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}


// @media (min-width: 1200px) {
//   .css-1oqqzyl-MuiContainer-root {
//     padding: 10px !important;
//       max-width: 1300px !important;
//   }
// }