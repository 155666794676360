// BORDER RADIUS
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

//   transition
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

//  transform property
@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin breakpoint($point) {
  @if $point == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == mlg {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == xxl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $point == xxxl {
    @media (min-width: 1600px) {
      @content;
    }
  } @else if $point == max-xxxl {
    @media (min-width: 1921px) {
      @content;
    }
  }

  //Matrial ui
  @if $point == sm-mui {
    @media (min-width: 600px) {
      @content;
    }
  }
  @if $point == max-xs {
    @media (max-width: 400px) {
      @content;
    }
  }
  @if $point == max-sm {
    @media (max-width: 480px) {
      @content;
    }
  }
  @if $point == max-lsm {
    @media (max-width: 567px) {
      @content;
    }
  }
  @if $point == max-md {
    @media (max-width: 767.98px) {
      @content;
    }
  }
  @if $point == max-sm-mui {
    @media (max-width: 899.98px) {
      @content;
    }
  }
  @if $point == md-mui {
    @media (min-width: 900px) {
      @content;
    }
  }
  @if $point == max-md-mui {
    @media (max-width: 1199.98px) {
      @content;
    }
  }
  @if $point == lg-mui {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @if $point == max-lg-mui {
    @media (max-width: 1535.98px) {
      @content;
    }
  }
  @if $point == xl-mui {
    @media (min-width: 1536px) {
      @content;
    }
  }
}
