@import './mixins';

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@include breakpoint(sm) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include breakpoint(md) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include breakpoint(lg) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include breakpoint(xl) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@include breakpoint(max-xl) {
  .d-max-xl-none {
    display: none !important;
  }

  .d-max-xl-inline {
    display: inline !important;
  }

  .d-max-xl-inline-block {
    display: inline-block !important;
  }

  .d-max-xl-block {
    display: block !important;
  }

  .d-max-xl-table {
    display: table !important;
  }

  .d-max-xl-table-row {
    display: table-row !important;
  }

  .d-max-xl-table-cell {
    display: table-cell !important;
  }

  .d-max-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-max-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include breakpoint(max-sm-mui) {
  .d-max-sm-mui-none {
    display: none !important;
  }

  .d-max-sm-mui-inline {
    display: inline !important;
  }

  .d-max-sm-mui-inline-block {
    display: inline-block !important;
  }

  .d-max-sm-mui-block {
    display: block !important;
  }

  .d-max-sm-mui-table {
    display: table !important;
  }

  .d-max-sm-mui-table-row {
    display: table-row !important;
  }

  .d-max-sm-mui-table-cell {
    display: table-cell !important;
  }

  .d-max-sm-mui-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-max-sm-mui-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include breakpoint(max-md-mui) {
  .d-max-md-mui-none {
    display: none !important;
  }

  .d-max-md-mui-inline {
    display: inline !important;
  }

  .d-max-md-mui-inline-block {
    display: inline-block !important;
  }

  .d-max-md-mui-block {
    display: block !important;
  }

  .d-max-md-mui-table {
    display: table !important;
  }

  .d-max-md-mui-table-row {
    display: table-row !important;
  }

  .d-max-md-mui-table-cell {
    display: table-cell !important;
  }

  .d-max-md-mui-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-max-md-mui-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@include breakpoint(max-lg-mui) {
  .d-max-lg-mui-none {
    display: none !important;
  }

  .d-max-lg-mui-inline {
    display: inline !important;
  }

  .d-max-lg-mui-inline-block {
    display: inline-block !important;
  }

  .d-max-lg-mui-block {
    display: block !important;
  }

  .d-max-lg-mui-table {
    display: table !important;
  }

  .d-max-lg-mui-table-row {
    display: table-row !important;
  }

  .d-max-lg-mui-table-cell {
    display: table-cell !important;
  }

  .d-max-lg-mui-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-max-lg-mui-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
