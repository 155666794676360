@import '../../../assets/styles/var';

.loader-container {
  width: 100%;
  height: 100%;
  //   background: $gray50;
  display: flex;
  align-items: center;
  justify-content: center;
}
